<template>
    <article>
        <hero-image
            :alt="hero.alt"
            :heading="hero.heading"
            :img-small="hero.imgSmall"
            :img-large="hero.imgLarge"
        />
        <v-container>
            <v-row align="center" class="home-intro">
                <v-col cols="12" sm="7" class="content my-0">
                    <h2 class="home-intro__title">
                        We are Sydney Kids Neuropsychology
                    </h2>
                    <p class="blockquote my-0">
                        Sydney Kids Neuropsychology specialises in
                        neuropsychological
                        <router-link to="/services">
                            services
                        </router-link>&nbsp;- comprehensive assessment and intervention
                        planning to children and adolescents with a range of
                        psychological, neurological and developmental
                        conditions.
                    </p>
                    <p class="blockquote">
                        Sydney Kids Neuropsychology provides services within the
                        Greater Sydney area and surrounds. Our assessments can
                        even be conducted COVID safe within your child's school or at your
                        own home!
                        <router-link to="/contact">
                            Contact us
                        </router-link>&nbsp;to book an appointment or make an enquiry.
                    </p>
                    <social :meta="meta" />
                </v-col>
                <v-col cols="12" sm="5">
                    <div class="home-intro__logo">
                        <v-img
                            alt="Sydney Kids Neuropsychology"
                            height="235"
                            src="/img/logo-stacked.svg"
                            width="241"
                        />
                        <v-btn
                            :color="$vuetify.theme.themes.light.primary"
                            large
                            rounded
                            outlined
                            to="/contact"
                            class="home-intro__btn"
                        >
                            contact us
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-lazy
            v-model="servicesVisible"
            :min-height="parallax.height"
            :options="{
                threshold: 0.1
            }"
            transition="slide-y-transition"
        >
            <parallax-image :alt="parallax.alt" :height="parallax.height">
                <v-container>
                    <v-row justify="center">
                        <v-col cols="12">
                            <h2 class="service-heading">
                                Our Services
                            </h2>
                        </v-col>
                        <v-col
                            v-for="service in services"
                            :key="service.class"
                            cols="12"
                            sm="6"
                            md="4"
                            xl="2"
                        >
                            <services-card
                                :modifier="service.class"
                                :anchor="service.anchor"
                                :icon="service.icon"
                                :title="service.title"
                                :description="service.description"
                                :cta="service.cta"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </parallax-image>
        </v-lazy>
        <v-container>
            <v-row>
                <v-col>
                    <div class="home-quote content">
                        <v-icon :color="$vuetify.theme.themes.light.primary" size="36" class="home-quote__icon">
                            $vuetify.icons.quote
                        </v-icon>
                        <p class="home-quote__quote">
                            We aim to understand the relationship between
                            thinking, learning and behaviour. By providing
                            insight into your child's learning and development
                            we can help your child reach their full potential!
                        </p>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </article>
</template>

<script>
import { _MxAppViewClass, _MxMeta } from '@/mixins/global';
import HeroImage from '@/components/image/HeroImage';
import Social from '@/components/social/Social';
import ParallaxImage from '@/components/image/ParallaxImage';
import ServicesCard from '@/components/card/ServicesCard';
export default {
    name: 'Home',
    components: {
        HeroImage,
        ParallaxImage,
        ServicesCard,
        Social
    },
    mixins: [_MxAppViewClass, _MxMeta],
    data() {
        return {
            hero: {
                alt: 'Sydney Kids Neuropsychology',
                heading: 'Sydney Kids Neuropsychology',
                imgSmall: 'hero-11-small',
                imgLarge: 'hero-11-large'
            },
            meta: {
                description:
                    'Neuropsychology practice in Sydney specialising in child and adolescent neuropsychological assessments.',
                title: 'Sydney Kids Neuropsychology'
            },
            parallax: {
                alt: 'Sydney Kids Neuropsychology',
                height: null
            },
            services: [
                {
                    class: 'service-card--autism',
                    anchor: '/services/autism-spectrum-disorder',
                    icon: '$vuetify.icons.autism',
                    title: 'Autism Spectrum Disorder',
                    description:
                        'Determine if your child has Autism Spectrum Disorder.',
                    cta: 'read more'
                },
                {
                    class: 'service-card--intellectual',
                    anchor: '/services/intellectual-functioning',
                    icon: '$vuetify.icons.intellectual',
                    title: 'Intellectual Functioning',
                    description:
                        'An IQ assessment can help to ascertain cognitive strengths and weaknesses.',
                    cta: 'read more'
                },
                {
                    class: 'service-card--school',
                    anchor: '/services/school-readiness',
                    icon: '$vuetify.icons.school',
                    title: 'School Readiness',
                    description:
                        'Help determine if your child is intellectually and emotionally ready to start school.',
                    cta: 'read more'
                },
                {
                    class: 'service-card--academic',
                    anchor: '/services/academic-assessment',
                    icon: '$vuetify.icons.academic',
                    title: 'Academic Assessment',
                    description:
                        'Help support your childs learning with an Academic Assessment.',
                    cta: 'read more'
                },
                {
                    class: 'service-card--attention',
                    anchor: '/services/attention-difficulties',
                    icon: '$vuetify.icons.attention',
                    title: 'Attention Difficulties',
                    description:
                        'Establish whether your child has an underlying attention deficit disorder.',
                    cta: 'read more'
                },
                {
                    class: 'service-card--comprehensive',
                    anchor: '/services/comprehensive-neuropsychology',
                    icon: '$vuetify.icons.comprehensive',
                    title: 'Comprehensive Neuropsychology',
                    description:
                        'Examine domains of intellectual and cognitive functioning tailored to your child.',
                    cta: 'read more'
                }
            ],
            servicesVisible: false
        };
    },
    created() {
        this.calcParallaxHeight();
    },
    methods: {
        calcParallaxHeight() {
            if (this.$vuetify.breakpoint.xsOnly) {
                this.parallax.height = 2433;
            }
            if (this.$vuetify.breakpoint.smOnly) {
                this.parallax.height = 1331;
            }
            if (this.$vuetify.breakpoint.mdAndUp) {
                this.parallax.height = 968;
            }
            if (this.$vuetify.breakpoint.xlOnly) {
                this.parallax.height = 605;
            }
        }
    }
};
</script>

<style lang="scss">
.app-Home {
    .social__btn {
        margin: 0 16px 0 0;
    }
}
</style>

<style scoped lang="scss">
.home-intro {
    &__title {
        margin-top: gutter-size(6) !important;
    }
    &__logo {
        margin: 0 auto;
        text-align: center;
        width: 241px;
        @media only screen and (min-width: #{map-get($grid-breakpoints, "sm")}) {
            float: right;
            margin: 0;
        }
    }
    &__btn {
        margin-top: gutter-size(8);
    }
}
.social {
    display: block;
    @media only screen and (max-width: #{map-get($grid-breakpoints, "sm") - 1}) {
        text-align: center;
    }
}
.service-heading {
    color: #111;
    font-family: $heading-font-family;
    font-size: $font-size-root * 1.777;
    font-weight: map-get($font-weights, 'regular');
    line-height: $line-height-sm;
    margin-bottom: gutter-size(5);
    text-align: center;
    text-transform: uppercase;
}
.home-quote {
    margin: gutter-size(8) 0;
    text-align: center;
    &__icon {
        margin-top: -#{gutter-size(1)};
    }
    &__quote {
        display: inline;
        margin: 0 0 0 gutter-size(8) !important;
    }
}
</style>
